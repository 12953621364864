import '@fontsource-variable/inter'
import '@/assets/main.postcss'
import.meta.glob('@/modules/*.js', { eager: true }) // things too small or TTI-dependent for entire Vue components
import { createApp } from 'vue'
import { VueQueryPlugin } from '@tanstack/vue-query'

// Create top-level Vue components
const components = import.meta.glob('@/components/*.{js,vue}', { import: 'default' })
Object.entries(components).forEach(async ([path, loadComponent]) => {
    const name = path.match(/([^/]+?)\..+$/)?.[1]
    const els = document.querySelectorAll(`[data-${name}]`)
    if (!els.length) return
    const Component = /** @type {import('vue').Component} */ (await loadComponent())
    for (const el of els) {
        const rawProps = el.getAttribute(`data-${name}`)
        createApp(Component, rawProps ? JSON.parse(rawProps) : null)
            .use(VueQueryPlugin)
            .mount(el)
    }
})