// Only allow one team member open at a time (not worth a whole Vue component),
// especially because Vue currently doesn't support v-model for <details>

const members = /** @type {NodeListOf<HTMLDetailsElement>} */(document.querySelectorAll('[data-team] details'))

/** @param {ToggleEvent & { currentTarget: HTMLDetailsElement }} event */
function closeOthers({ currentTarget }) {
    if (!currentTarget.open) return
    members.forEach((member) => {
        if (member !== currentTarget) member.open = false
    })
}
members.forEach((member) => member.addEventListener('toggle', /** @type {EventListener} */(closeOthers)))